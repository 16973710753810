import React, { FC, useEffect, useState } from "react";
import { Table, TagPicker, Drawer, IconButton, Col, DatePicker } from "rsuite";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import CloseIcon from "@rsuite/icons/Close";
import ReloadIcon from "@rsuite/icons/Reload";
import GpsLocationIcon from "@rsuite/icons/Location";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import dayjs from "dayjs";
import FormControlLabel from "rsuite/FormControlLabel";
import GpsConnection from "../../../../utils/connections/gps";
import { isEmpty } from "lodash";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as XLSX from "xlsx";

interface IGpsRouteReport {
  userId: string | null;
  dateFrom: Date | null;
  users: {
    id: string;
    name: string;
  }[];
}

export interface IGpsReportRequest {
  userIds: Array<string>;
  dateFrom: Date | null;
  dateTo: Date | null;
}

interface IRoutePoint {
  projectId: string;
  projectName: string;
  locationName: string;
  userName: string;
  routeDate: string;
  pointDate: string;
  deviceId: string;
  pointDistance: string;
  workStart: string;
  workEnd: string;
  locationDistance: string;
  routeDistance: string;
  routeNextDate: string;
}

interface IRoutesData {
  activityPoint: IRoutePoint;
  routePoints: IRoutePoint[];
}

const GpsRouteReport: FC<IGpsRouteReport> = (props) => {
  const defaultRequest = {
    userIds: [],
    dateFrom: null,
    dateTo: null,
  };
  const [isOpen, setIsOpen] = useState(false);
  const [request, setRequest] = useState<IGpsReportRequest>(defaultRequest);
  const [routesData, setRoutesData] = useState<IRoutesData[] | undefined>(
    undefined
  );
  const handleLoad = () => {
    setRoutesData(undefined);
    const requestData = { ...request };
    if (isEmpty(requestData.userIds)) {
      requestData.userIds = props?.users?.map((u) => u.id);
    }
    GpsConnection.routeReport(requestData)
      .then((response) => {
        setRoutesData(response.data.routes.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleExcel = () => {
    if (!routesData) return;

    const mappedData = routeTableData?.map((point) => {
      return {
        Projekt: point.projectName,
        Lokalizacja: point.locationName,
        Osoba: point.userName,
        Data: point.pointDate,
        Punkt:
          point.type == "ROUTE"
            ? "P-" + point.pointNumber
            : "Punkt " + point.pointNumber,
        "GPS (godzina start)": point.workStart,
        "Godzina start":
          point.type == "ROUTE"
            ? dayjs(point.routeDate).format("HH:mm")
            : dayjs(point.workStart).format("HH:mm"),
        "Godzina koniec":
          point.type == "ROUTE"
            ? dayjs(point.routeNextDate).format("HH:mm")
            : dayjs(point.workEnd).format("HH:mm"),
        "ID telefonu": point.deviceId,
        "Odległość od punktu":
          point.type == "ROUTE" ? point.routeDistance : point.locationDistance,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(mappedData || []);

    worksheet["!cols"] = [
      { wch: 15 }, // "Projekt"
      { wch: 20 }, // "Lokalizacja"
      { wch: 15 }, // "Osoba"
      { wch: 12 }, // "Data"
      { wch: 10 }, // "Punkt"
      { wch: 18 }, // "GPS (godzina start)"
      { wch: 15 }, // "Godzina start"
      { wch: 15 }, // "Godzina koniec"
      { wch: 18 }, // "ID telefonu"
      { wch: 20 }, // "Odległość od punktu"
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Trasy");

    XLSX.writeFile(workbook, "Raport tras.xlsx");
  };

  useEffect(() => {
    setRequest((s) => ({
      ...s,
      userIds: props.userId ? [props.userId] : [],
      dateFrom: props.dateFrom,
      dateTo: null,
    }));
  }, [props.userId, props.dateFrom]);

  const routeTableData = routesData?.flatMap((point, ai) => [
    {
      ...point.activityPoint,
      type: "ACTIVITY",
      pointNumber: String.fromCharCode(ai + 65),
    },
    ...(point.routePoints?.map((routePoint, ri) => ({
      ...routePoint,
      type: "ROUTE",
      pointNumber: ri + 1,
    })) ?? []),
  ]);

  return (
    <>
      <HeaderButtons>
        <IconButton
          appearance={"ghost"}
          icon={<GpsLocationIcon />}
          onClick={() => {
            setIsOpen(true);
          }}>
          Raport tras
        </IconButton>
      </HeaderButtons>
      <Drawer
        placement={"right"}
        style={{ height: "100%" }}
        size={"full"}
        key={"drawer-gps-routes"}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}>
        <Drawer.Header>
          <Drawer.Title>Raport tras</Drawer.Title>
          <Drawer.Actions>
            <IconButton
              icon={<CloseIcon />}
              appearance={"ghost"}
              onClick={() => {
                setIsOpen(false);
              }}>
              Zamknij
            </IconButton>
            <IconButton
              icon={<ReloadIcon />}
              onClick={handleLoad}
              appearance="ghost">
              Pobierz
            </IconButton>
            <IconButton
              disabled={routesData === undefined}
              icon={<FileDownloadIcon />}
              onClick={handleExcel}
              appearance="ghost">
              .XLS
            </IconButton>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{ padding: "20px" }}>
          <Col xs={12}>
            <FormControlLabel>Użytkownik:</FormControlLabel>
            <TagPicker
              block
              value={request?.userIds}
              labelKey={"name"}
              valueKey={"id"}
              data={props.users}
              onChange={(values) => {
                setRequest((ps) => ({ ...ps, userIds: values }));
              }}
              placeholder={"Wybierz użytkowników"}
            />
          </Col>
          <Col xs={6}>
            <FormControlLabel>Data od:</FormControlLabel>
            <DatePicker
              block
              oneTap
              isoWeek
              showWeekNumbers
              format={"yyyy-MM-dd"}
              value={
                request?.dateFrom ? dayjs(request.dateFrom).toDate() : null
              }
              onChange={(date) => {
                setRequest((ps) => ({ ...ps, dateFrom: date }));
              }}
              ranges={[
                {
                  label: "Początek tygodnia",
                  value: dayjs().startOf("week").toDate(),
                },
                {
                  label: "Dziś",
                  value: new Date(),
                },
              ]}
              placeholder={"data początkowa"}
            />
          </Col>
          <Col xs={6}>
            <FormControlLabel>Data do:</FormControlLabel>
            <DatePicker
              block
              oneTap
              isoWeek
              showWeekNumbers
              format={"yyyy-MM-dd"}
              value={request?.dateTo ? dayjs(request.dateTo).toDate() : null}
              onChange={(date) => {
                setRequest((ps) => ({ ...ps, dateTo: date }));
              }}
              ranges={[
                {
                  label: "Koniec tygodnia",
                  value: dayjs().endOf("week").toDate(),
                },
                {
                  label: "Dziś",
                  value: new Date(),
                },
              ]}
              placeholder={"data końcowa"}
            />
          </Col>
          <SeparatorEmpty />
          <Col xs={24}>
            <Table
              autoHeight
              rowHeight={25}
              style={{ width: "100%" }}
              data={routeTableData}
              loading={routesData === undefined}>
              <Table.Column flexGrow={1} resizable>
                <Table.HeaderCell>Projekt</Table.HeaderCell>
                <Table.Cell dataKey={"projectName"}>
                  {(rowData) => {
                    return <>{rowData.projectName}</>;
                  }}
                </Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1} resizable>
                <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                <Table.Cell>{(row) => <>{row.userName}</>}</Table.Cell>
              </Table.Column>
              <Table.Column flexGrow={1} resizable>
                <Table.HeaderCell>Data</Table.HeaderCell>
                <Table.Cell dataKey={"pointDate"} />
              </Table.Column>

              <Table.Column flexGrow={1} resizable>
                <Table.HeaderCell>Lokalizacja</Table.HeaderCell>
                <Table.Cell dataKey={"locationName"}>
                  {(rowData) => {
                    if (rowData.type == "ACTIVITY")
                      return (
                        <>
                          {rowData.pointNumber}-{rowData.locationName}
                        </>
                      );
                    if (rowData.type == "ROUTE")
                      return <>P-{rowData.pointNumber}</>;
                    return <></>;
                  }}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} resizable>
                <Table.HeaderCell>GPS (godzina start)</Table.HeaderCell>
                <Table.Cell dataKey={"workStart"}>
                  {(rowData) => {
                    if (rowData.type == "ACTIVITY")
                      return <>{dayjs(rowData.workStart).format("HH:mm")}</>;
                    return <></>;
                  }}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} resizable>
                <Table.HeaderCell>godzina start</Table.HeaderCell>
                <Table.Cell dataKey={"workStart"}>
                  {(rowData) => {
                    if (rowData.type == "ACTIVITY")
                      return <>{dayjs(rowData.workStart).format("HH:mm")}</>;
                    if (rowData.type == "ROUTE")
                      return <>{dayjs(rowData.routeDate).format("HH:mm")}</>;
                    return <></>;
                  }}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} resizable>
                <Table.HeaderCell>godzina koniec</Table.HeaderCell>
                <Table.Cell dataKey={"workStart"}>
                  {(rowData) => {
                    if (rowData.type == "ACTIVITY")
                      return <>{dayjs(rowData.workEnd).format("HH:mm")}</>;
                    if (rowData.type == "ROUTE")
                      return (
                        <>{dayjs(rowData.routeNextDate).format("HH:mm")}</>
                      );
                    return <></>;
                  }}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} resizable>
                <Table.HeaderCell>Id telefonu</Table.HeaderCell>
                <Table.Cell dataKey={"deviceId"} />
              </Table.Column>

              <Table.Column fixed={"right"} resizable>
                <Table.HeaderCell>Odległość od punktu</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => {
                    if (rowData.type == "ACTIVITY")
                      return <>{rowData.locationDistance}</>;
                    if (rowData.type == "ROUTE")
                      return <>{rowData.routeDistance}</>;
                    return <></>;
                  }}
                </Table.Cell>
              </Table.Column>
            </Table>
          </Col>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default GpsRouteReport;
