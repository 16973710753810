import React, { FunctionComponent, useEffect, useState } from "react";
import Modal from "rsuite/Modal";
import {
  Checkbox,
  Col,
  DatePicker,
  IconButton,
  InputNumber,
  Nav,
  SelectPicker,
  Toggle,
} from "rsuite";
import Button from "rsuite/Button";
import { IModalData, ScheduleInterval } from "../Schedule";
import { IUserSimple } from "../../../utils/models";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import { WEEK_DAYS_OBJ } from "../../../utils/dictionaries";
import { IScheduleRequestData } from "../Hooks/useRequestData";
import SchedulesConnection from "utils/connections/schedules";
import SpinnerSmall from "global/atoms/Spinner/SpinnerSmall";
import dayjs from "dayjs";
import localePl from "dayjs/locale/pl";
import FormControlLabel from "rsuite/FormControlLabel";
import customParseFormat from "dayjs/plugin/customParseFormat";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import FormErrorMessage from "rsuite/FormErrorMessage";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import ToastNotificationPush, {
  ToastTypes,
} from "../../../global/ToastNotification";
import { sortBy } from "lodash";
import ActionsContainer from "../../../global/atoms/ActionsContainer";

dayjs.locale(localePl);
dayjs.extend(customParseFormat);

interface IScheduleModal {
  modalData: IModalData | null;
  onModalClose: () => void;
  onScheduleRemove: () => void;
  onModalSubmit: () => void;
  requestData: IScheduleRequestData;
  hoursStrategy?: string;
}

interface IHourData {
  day?: number;
  hours?: number | string | null;
  start?: string | null;
  end?: string | null;
  type: string;
}

export interface IScheduleModalData {
  type: ScheduleInterval;
  startDate: Date | null;
  endDate: Date | null | undefined;
  fromDate: Date | null | undefined;
  days: Array<number>;
  projectId: string;
  taskId: string;
  locationId: string;
  userId: string;
  hours?: Array<IHourData>;
  isApproved: boolean;
  users?: Array<IUserSimple>;
}

const TIME_NUMBER = "HOURS_EXPLICITLY";
const TIME_STRING = "TIME";
const DEFAULT_DAY = {
  day: undefined,
  hours: undefined,
  start: undefined,
  end: undefined,
  type: TIME_NUMBER,
};

const COMMON_FOR_WEEK = "COMMON_FOR_WEEK";
const ScheduleModal: FunctionComponent<IScheduleModal> = (props) => {
  const [errors, setErrors] = useState<any>(null);
  const [modalData, setModalData] = useState<IScheduleModalData | null>(null);
  const [hoursData, setHoursData] = useState<Array<IHourData> | null>(null);
  const [hoursDataSingle, setHoursDataSingle] = useState<IHourData | null>(
    null
  );
  const [open, setOpen] = useState(false);

  /** `isEnabled=true` when `props.modalData.type` in create or modify mode*/
  const isEnabled = ["create", "modify"].includes(props?.modalData?.type || "");
  /** `isModify=true` when `props.modalData.type` in modify mode*/
  const isModify = ["modify"].includes(props?.modalData?.type || "");
  /** `isCreate=true` when `props.modalData.type` is in create mode */
  const isCreate = ["create"].includes(props?.modalData?.type || "");
  /** `isisView=true` when `props.modalData.type` is in preview mode */
  const isView = ["view"].includes(props?.modalData?.type || "");

  useEffect(() => {
    if (props.modalData && (isEnabled || isView)) {
      setOpen(true);
      SchedulesConnection.modalData(
        props.modalData.rowId,
        props.modalData.timeTableId
      ).then((res) => {
        const tmp = res.data;
        if (!props.modalData?.timeTableId && props.modalData?.date) {
          tmp.startDate = props.modalData.date;
        }
        // tmp fix - date parser not applying for some reason on that endpoint
        if (typeof tmp.startDate !== "object" && tmp.startDate)
          tmp.startDate = dayjs(tmp.startDate).toDate();
        if (typeof tmp.endDate !== "object" && tmp.endDate)
          tmp.endDate = dayjs(tmp.endDate).toDate();
        tmp.days = Object.values(tmp.days);
        setModalData(tmp);
        if (tmp?.type === ScheduleInterval.Single)
          setHoursDataSingle((tmp?.hours ?? [])[0] ?? DEFAULT_DAY);
        else {
          setHoursData(tmp.hours ?? []);
          /** `setHoursDataSingle` is set to first day of the week */
          if (props.hoursStrategy == COMMON_FOR_WEEK) {
            setHoursDataSingle((tmp?.hours ?? [])[0] ?? DEFAULT_DAY);
          }
        }
      });
    } else {
      setOpen(false);
      setModalData(null);
      setHoursData(null);
      setHoursDataSingle(null);
    }
  }, [props.modalData]);

  const intervals = {
    [ScheduleInterval.Weekly]: "Raz na tydzień",
    [ScheduleInterval.Weekly2]: "Raz na 2 tygodnie",
    [ScheduleInterval.Weekly3]: "Raz na 3 tygodnie",
    [ScheduleInterval.Weekly4]: "Raz na cztery tygodnie",
  };

  const isReadyToSave = (): boolean => {
    if (!modalData) return false;
    if (!modalData.userId) return false;
    if (!modalData.projectId) return false;
    if (!modalData?.taskId) return false;

    if (modalData.type !== ScheduleInterval.Single) {
      // recur schedule
      if (!modalData.days?.length) return false; // no-days selected in recur schedule
      if (!modalData.startDate) return false; // empty start date
    }

    return true;
  };

  const hourDay = (dayNumber: number): IHourData | undefined => {
    return hoursData?.find((d) => d.day == dayNumber);
  };
  const getTimeToDate = (dayKey: number, field: string) => {
    const day = hourDay(dayKey);
    const value = day?.[field];
    return value ? dayjs(value, "HH:mm").toDate() : undefined;
  };
  const isNumberRange = (dayNumber: number) => {
    const day = hourDay(dayNumber);
    return day?.type != TIME_STRING;
  };

  const updateState = (value: Partial<IScheduleModalData>) => {
    setModalData((s) => {
      if (!s) return s;
      return { ...s, ...value };
    });
  };

  useEffect(() => {
    if (
      props.hoursStrategy == COMMON_FOR_WEEK &&
      modalData &&
      modalData.type !== ScheduleInterval.Single
    ) {
      setHoursData(() => {
        return modalData.days.map((d) => {
          return { ...hoursDataSingle, day: d } as IHourData;
        });
      });
    }
  }, [hoursDataSingle, modalData?.days, props.hoursStrategy]);

  const normalizeDay = (day) => {
    if (!day) {
      return;
    }
    if (day?.type == TIME_NUMBER) {
      day.start = null;
      day.end = null;
      day.hours = Number(day.hours) ?? 0;
    } else if (day?.type == TIME_STRING) {
      day.hours = null;
    }
    return day;
  };
  const parseData = () => {
    let days: Array<IHourData> = [];
    if (modalData?.type == ScheduleInterval.Single) {
      const day = normalizeDay(hoursDataSingle);
      day.day = 0;
      if (day) {
        days = [day];
      }
    } else {
      days = hoursData ?? [];
      days = sortBy(days, "day").filter(
        (d) => modalData?.days?.includes(d.day ?? -1)
      );
      days = days.map((d) => normalizeDay(d));
    }
    return {
      ...modalData,
      hours: days,
      fromDate: modalData?.fromDate
        ? dayjs(modalData.fromDate).format("YYYY-MM-DD")
        : null,
      startDate: dayjs(props.modalData?.date).format("YYYY-MM-DD"),
      endDate: modalData?.endDate
        ? dayjs(modalData.endDate).format("YYYY-MM-DD")
        : null,
    };
  };

  const parseErrors = (err, data) => {
    {
      if (err.response.data.message) {
        ToastNotificationPush(ToastTypes.warning, err.response.data.message);
      }
      if (err.response.status == 400) {
        ToastNotificationPush(ToastTypes.warning, "Formularz zawiera błędy");
        const _errors = err.response.data.errors;
        if (_errors.hours) {
          const mappedErrors = data.days.map((day: number, index: number) => ({
            day: day,
            ...(_errors.hours[index] ?? []),
          }));
          setErrors({ ..._errors, hours: mappedErrors });
        } else {
          setErrors(err.response.data.errors);
        }
      }
    }
  };

  const handleSubmitAdd = () => {
    setErrors(null);
    const _data = parseData();
    SchedulesConnection.add(_data)
      .then((response) => {
        if (response.status == 200) {
          ToastNotificationPush(ToastTypes.info, "Harmonogram został dodany");
          props.onModalSubmit?.();
        }
      })
      .catch((err) => {
        {
          if (err.response.data.message) {
            ToastNotificationPush(
              ToastTypes.warning,
              err.response.data.message
            );
          }
          if (err.response.status == 400) {
            ToastNotificationPush(
              ToastTypes.warning,
              "Formularz zawiera błędy"
            );
            setErrors((e) => ({
              ...e,
              ...err.response.data.errors,
              hours: err.response.data.errors.hours[0],
            }));
          }
        }
      });
  };
  const handleSave = () => {
    setErrors(null);
    if (modalData && props?.modalData?.timeTableId) {
      const _data = parseData();
      SchedulesConnection.scheduleUpdate({
        ..._data,
        id: props.modalData?.timeTableId,
      })
        .then((response) => {
          if (response.status == 200) {
            ToastNotificationPush(ToastTypes.info, "Zmiany zostały zapisane");
            props.onModalSubmit?.();
          }
        })
        .catch((err) => parseErrors(err, _data));
    }
  };

  const scheduleHoursRecurringPicker = () => {
    if (!modalData) return <SpinnerSmall />;
    const HourErrorMessage = (props) => {
      const { dayKey, dataKey } = props;

      const error = errors?.hours?.find((e) => e?.day == dayKey);
      console.log({
        errors: errors,
        error: error,
        dayKey: dayKey,
        dataKey: dataKey,
      });
      if (!error) return <></>;
      if (error?.[dataKey])
        return (
          <span
            style={{
              color: "red",
              position: "absolute",
              top: "0px",
              right: "5px",
              zIndex: "50",
            }}>
            *
          </span>
        );
      return <></>;
    };

    return (
      <>
        {isEnabled && props.hoursStrategy == COMMON_FOR_WEEK && (
          <>
            {scheduleHoursSinglePicker()}
            <SeparatorEmpty />
          </>
        )}
        <Col xs={12}>
          <FormControlLabel>
            <strong>Dni tygodnia</strong>
          </FormControlLabel>
        </Col>
        <Col xs={12}>
          <FormControlLabel>
            {" "}
            <strong>Godziny</strong>
          </FormControlLabel>
        </Col>

        {WEEK_DAYS_OBJ.map((wd) => (
          <>
            <Col xs={24} style={{ display: "flex", alignItems: "center" }}>
              <Col xs={10}>
                <Checkbox
                  checked={(modalData.days ?? []).includes(wd.key)}
                  key={`k-${wd.key}`}
                  style={{
                    display: "block",
                    opacity: (modalData.days ?? []).includes(wd.key)
                      ? "1"
                      : "0.5",
                    padding: 0,
                  }}
                  readOnly={!isEnabled}
                  onChange={(value, checked) => {
                    const daysFiltered = (modalData?.days ?? []).filter(
                      (_d) => _d !== wd.key
                    );
                    updateState({
                      days: checked
                        ? daysFiltered.concat([wd.key])
                        : daysFiltered,
                    });
                  }}>
                  {" "}
                  {wd.name}
                </Checkbox>
              </Col>
              {(modalData.days ?? []).includes(wd.key) && (
                <>
                  <Col xs={4}>
                    <Toggle
                      size="md"
                      checkedChildren={"Zakres"}
                      unCheckedChildren={"Liczba"}
                      checked={!isNumberRange(wd.key)}
                      disabled={
                        !isEnabled || props.hoursStrategy == COMMON_FOR_WEEK
                      }
                      onChange={(checked) => {
                        setHoursData((s) => {
                          const day = s?.find((d) => d.day == wd.key) ?? {
                            type: TIME_STRING,
                            day: wd.key,
                            hours: undefined,
                            start: undefined,
                            end: undefined,
                          };
                          day.type = checked ? TIME_STRING : TIME_NUMBER;
                          return [
                            ...(s?.filter((h) => h.day != wd.key) ?? []),
                            day,
                          ];
                        });
                      }}
                    />
                  </Col>
                  {!isNumberRange(wd.key) && (
                    <>
                      <Col xs={5} style={{ position: "relative" }}>
                        <DatePicker
                          block
                          size={"sm"}
                          format="HH:mm"
                          cleanable={true}
                          disabled={
                            !isEnabled || props.hoursStrategy == COMMON_FOR_WEEK
                          }
                          value={getTimeToDate(wd.key, "start")}
                          onChange={(value) => {
                            setHoursData((s) => {
                              const day = s?.find((d) => d.day == wd.key) ?? {
                                type: TIME_STRING,
                                day: wd.key,
                                hours: 0,
                                start: value
                                  ? dayjs(value).format("HH:mm")
                                  : undefined,
                              };
                              day.start = value
                                ? dayjs(value).format("HH:mm")
                                : undefined;
                              return [
                                ...(s?.filter((h) => h.day != wd.key) ?? []),
                                day,
                              ];
                            });
                          }}
                          ranges={[
                            {
                              label: "8:00",
                              value: dayjs().hour(8).minute(0).toDate(),
                            },
                            {
                              label: "10:00",
                              value: dayjs().hour(10).minute(0).toDate(),
                            },
                          ]}
                        />
                        <HourErrorMessage dayKey={wd.key} dataKey={"start"} />
                      </Col>
                      <Col xs={5} style={{ position: "relative" }}>
                        <DatePicker
                          block
                          size={"sm"}
                          format="HH:mm"
                          cleanable={true}
                          disabled={
                            !isEnabled || props.hoursStrategy == COMMON_FOR_WEEK
                          }
                          value={getTimeToDate(wd.key, "end")}
                          onChange={(value) => {
                            setHoursData((s) => {
                              const day = s?.find((d) => d.day == wd.key) ?? {
                                type: TIME_STRING,
                                day: wd.key,
                                hours: 0,
                                end: value
                                  ? dayjs(value).format("HH:mm")
                                  : undefined,
                              };
                              day.end = value
                                ? dayjs(value).format("HH:mm")
                                : undefined;
                              return [
                                ...(s?.filter((h) => h.day != wd.key) ?? []),
                                day,
                              ];
                            });
                          }}
                          ranges={[
                            {
                              label: "16:00",
                              value: dayjs().hour(16).minute(0).toDate(),
                            },
                            {
                              label: "18:00",
                              value: dayjs().hour(18).minute(0).toDate(),
                            },
                          ]}
                        />
                        <HourErrorMessage dayKey={wd.key} dataKey={"end"} />
                      </Col>
                    </>
                  )}
                  {isNumberRange(wd.key) && (
                    <Col xs={10}>
                      <InputNumber
                        size={"sm"}
                        value={hourDay(wd.key)?.hours ?? undefined}
                        step={0.01}
                        min={0}
                        max={100}
                        disabled={
                          !isEnabled || props.hoursStrategy == COMMON_FOR_WEEK
                        }
                        onChange={(value: number | string) => {
                          setHoursData((s) => {
                            const day = s?.find((h) => h.day == wd.key) ?? {
                              day: wd.key,
                              hours: undefined,
                              start: undefined,
                              end: undefined,
                              type: TIME_NUMBER,
                            };
                            day.hours = value;
                            const days =
                              s?.filter((h) => h.day != wd.key) ?? [];
                            return [...days, day];
                          });
                        }}
                      />
                    </Col>
                  )}
                </>
              )}
            </Col>
          </>
        ))}
        <SeparatorEmpty />
        <Col xs={24}>
          <FormControlLabel>
            <strong>Częstotliwość</strong>
          </FormControlLabel>
          <SelectPicker
            data={Object.entries(intervals).map((v) => ({
              label: v[1],
              value: parseInt(v[0]),
            }))}
            block
            disabled={!isEnabled}
            value={modalData.type}
            cleanable={false}
            searchable={false}
            placeholder={"wybierz"}
            onChange={(value) => updateState({ type: Number(value) })}
          />
        </Col>

        <SeparatorEmpty size={1.5} />

        <Col xs={24}>
          <FormControlLabel>
            <strong>
              Harmonogram od - do&nbsp;&nbsp;
              <Toggle
                size="md"
                checkedChildren={"∞"}
                unCheckedChildren={"data końcowa"}
                checked={modalData.endDate === null}
                disabled={!isEnabled}
                onChange={(val) => {
                  updateState({
                    endDate: !val ? undefined : null,
                  });
                }}
              />
            </strong>
          </FormControlLabel>
        </Col>
        <Col xs={24}>
          <Col xs={12}>
            <DatePicker
              block
              placement={"topStart"}
              cleanable={false}
              isoWeek
              oneTap
              disabled={!isEnabled}
              shouldDisableDate={(date) => dayjs(date).isBefore(dayjs())}
              onChange={(date) => updateState({ startDate: date })}
              value={
                props.modalData?.type === "modify"
                  ? props.modalData.date
                  : modalData.startDate ?? undefined
              }
            />
          </Col>
          <Col xs={12}>
            {modalData.endDate !== null && (
              <DatePicker
                block
                placement={"topStart"}
                isoWeek
                oneTap
                cleanable={false}
                disabled={!isEnabled}
                shouldDisableDate={(date) =>
                  dayjs(date).isBefore(modalData.startDate ?? dayjs())
                }
                onChange={(date) => updateState({ endDate: date })}
                value={modalData.endDate}
              />
            )}
            {modalData.endDate === null && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "8px",
                  color: "#a2a2a2",
                }}>
                Brak daty końcowej
              </div>
            )}
          </Col>
        </Col>
      </>
    );
  };
  const scheduleHoursSinglePicker = () => {
    return (
      <>
        <Col xs={24}>
          <FormControlLabel>
            <strong>Godziny</strong>
          </FormControlLabel>
        </Col>
        <Col xs={24} style={{ display: "flex", alignItems: "center" }}>
          <Col xs={4}>
            <Toggle
              size={"md"}
              checkedChildren={"Zakres"}
              unCheckedChildren={"Liczba"}
              checked={hoursDataSingle?.type === TIME_STRING}
              readOnly={!isEnabled}
              onChange={(checked) => {
                setHoursDataSingle((s) => ({
                  ...s,
                  type: checked ? TIME_STRING : TIME_NUMBER,
                }));
              }}
            />
          </Col>
          {hoursDataSingle?.type === TIME_STRING && (
            <>
              <Col xs={10}>
                <DatePicker
                  size={"sm"}
                  block
                  format="HH:mm"
                  cleanable={true}
                  readOnly={!isEnabled}
                  value={
                    hoursDataSingle.start
                      ? dayjs(hoursDataSingle.start, "HH:mm").toDate()
                      : undefined
                  }
                  onChange={(value) => {
                    setHoursDataSingle((s) => {
                      if (!s) return s;
                      return {
                        ...s,
                        start: value ? dayjs(value).format("HH:mm") : undefined,
                      };
                    });
                  }}
                  ranges={[
                    {
                      label: "8:00",
                      value: dayjs().hour(8).minute(0).toDate(),
                    },
                    {
                      label: "10:00",
                      value: dayjs().hour(10).minute(0).toDate(),
                    },
                  ]}
                />
                <FormErrorMessage show={errors?.hours?.start}>
                  {errors?.hours?.start}
                </FormErrorMessage>
              </Col>
              <Col xs={10}>
                <DatePicker
                  size={"sm"}
                  block
                  format="HH:mm"
                  cleanable={true}
                  readOnly={!isEnabled}
                  value={
                    hoursDataSingle.end
                      ? dayjs(hoursDataSingle.end, "HH:mm").toDate()
                      : undefined
                  }
                  onChange={(value) => {
                    setHoursDataSingle((s) => {
                      if (!s) return s;
                      return {
                        ...s,
                        end: value ? dayjs(value).format("HH:mm") : undefined,
                      };
                    });
                  }}
                  ranges={[
                    {
                      label: "16:00",
                      value: dayjs().hour(16).minute(0).toDate(),
                    },
                    {
                      label: "18:00",
                      value: dayjs().hour(18).minute(0).toDate(),
                    },
                  ]}
                />
                <FormErrorMessage show={errors?.hours?.end}>
                  {errors?.hours?.end}
                </FormErrorMessage>
              </Col>
            </>
          )}
          {hoursDataSingle?.type === TIME_NUMBER && (
            <Col xs={20}>
              <InputNumber
                size={"sm"}
                value={hoursDataSingle?.hours ?? undefined}
                step={0.01}
                min={0}
                max={100}
                readOnly={!isEnabled}
                onChange={(value) => {
                  setHoursDataSingle((s) => {
                    if (!s) return s;
                    return {
                      ...s,
                      hours: value,
                    };
                  });
                }}
              />
            </Col>
          )}
        </Col>
      </>
    );
  };

  return (
    <>
      <Modal
        open={open}
        size={"sm"}
        onExited={props.onModalClose}
        onClose={() => setOpen(false)}>
        <Modal.Header>
          <h4>
            {isCreate && "Nowy harmonogram"}
            {isModify && "Edycja harmonogramu"}
            {!isEnabled && "Podgląd harmonogramu"}
          </h4>
        </Modal.Header>

        <Modal.Body>
          <>
            {!modalData ? (
              <SpinnerSmall />
            ) : (
              <>
                {!isView && !isModify && (
                  <Col xs={24}>
                    <Nav
                      appearance="default"
                      justified
                      onSelect={(eventKey: string) =>
                        updateState({
                          type:
                            eventKey === "single"
                              ? ScheduleInterval.Single
                              : ScheduleInterval.Weekly,
                        })
                      }>
                      {/* @ts-ignore */}
                      <Nav.Item
                        eventKey={"single"}
                        active={modalData.type === ScheduleInterval.Single}>
                        Jednorazowy
                      </Nav.Item>
                      <Nav.Item
                        eventKey={"recurrent"}
                        active={modalData.type !== ScheduleInterval.Single}>
                        Cykliczny
                      </Nav.Item>
                    </Nav>
                  </Col>
                )}
                <SeparatorEmpty />
                <Col xs={24}>
                  <FormControlLabel>
                    <strong>Raportujący</strong>
                  </FormControlLabel>
                  <SelectPicker
                    data={modalData.users ?? []}
                    value={modalData.userId ?? undefined}
                    disabled={modalData.userId !== null || !isEnabled} //
                    valueKey={"id"}
                    labelKey={"fullName"}
                    cleanable={false}
                    searchable={true}
                    placeholder={"wybierz"}
                    block
                    onChange={(value) =>
                      updateState({ userId: value ?? undefined })
                    }
                  />
                </Col>
                <SeparatorEmpty />

                {/* hours for single */}
                {modalData.type === ScheduleInterval.Single &&
                  scheduleHoursSinglePicker()}
                {modalData.type === ScheduleInterval.Single && (
                  <Col xs={24}>
                    <SeparatorEmpty size={2} />
                    <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                      {dayjs(modalData.startDate).format("dddd, D MMMM YYYY")}
                    </h5>
                  </Col>
                )}
                <SeparatorEmpty />
                <Col xs={24}>
                  {modalData.type !== ScheduleInterval.Single &&
                    scheduleHoursRecurringPicker()}
                </Col>
              </>
            )}
          </>
        </Modal.Body>

        <Modal.Footer style={{ marginTop: "10px" }}>
          <ActionsContainer style={{ alignItems: "flex-end" }}>
            {props.modalData &&
              dayjs(props.modalData.date).isAfter(dayjs(), "day") && (
                <IconButton
                  icon={<TrashIcon />}
                  appearance={"ghost"}
                  color={"red"}
                  onClick={props.onScheduleRemove}>
                  Usuń
                </IconButton>
              )}
            {isCreate && (
              <Button
                appearance={"primary"}
                disabled={!isReadyToSave()}
                onClick={handleSubmitAdd}>
                Utwórz
              </Button>
            )}
            {isModify &&
              props.modalData &&
              dayjs(props.modalData.date).isAfter(dayjs(), "day") && (
                <>
                  <div
                    style={{
                      flexGrow: "2",
                      alignItems: "center",
                      textAlign: "left",
                    }}>
                    <FormControlLabel>
                      <strong>Zmiana obowiązuje od:</strong>
                    </FormControlLabel>
                    <DatePicker
                      block
                      isoWeek
                      oneTap
                      cleanable={false}
                      placement={"topStart"}
                      shouldDisableDate={(date) =>
                        dayjs(date).isBefore(
                          dayjs(modalData?.startDate).add(1, "day")
                        )
                      }
                      format={"yyyy-MM-dd"}
                      value={modalData?.fromDate}
                      onChange={(date) => updateState({ fromDate: date })}
                    />
                    <FormErrorMessage show={errors?.formatDate}>
                      {errors?.formatDate}
                    </FormErrorMessage>
                  </div>
                  <IconButton
                    disabled={!isEnabled || !modalData?.fromDate}
                    icon={<EditIcon />}
                    appearance={modalData?.fromDate ? "ghost" : "subtle"}
                    onClick={handleSave}>
                    Zapisz
                  </IconButton>
                </>
              )}
            <IconButton
              icon={<CloseOutlineIcon />}
              appearance={"ghost"}
              onClick={() => {
                setHoursData(null);
                setHoursDataSingle(null);
                props.onModalClose();
              }}>
              Zamknij
            </IconButton>
          </ActionsContainer>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduleModal;
